import axios from "axios";
import store from "./store";

const axiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});

// Request interceptor to attach authorization token
axiosInstance.interceptors.request.use(
  (config) => {
    const token = store.state.auth.data.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor to handle errors
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      const { data } = error.response;
      let err;
      if (data.errors) {
        err = data.errors[Object.keys(data.errors)[0]];
      } else if (data.message) {
        err = data.message;
        if (err === "Authorization token expired") {
          window.location.replace("/error/expired");
        }
        if(err === "No Authorization was found."){
          window.location.replace("/error/not-authorized");
        }
        if(err === "This is an invalid role."){
          window.location.replace("/error/something-went-wrong");
        }
      } else {
        err = error;
      }
      return Promise.reject(err);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;